<template>
  <q-separator v-if="separator" />
  <div
    class="q-my-lg"
    :class="[textColor, $q.screen.lt.md || !responsive ? 'q-px-lg' : 'q-px-xs']"
  >
    <div class="q-col-gutter-lg row">
      <div
        v-for="(callout, key) in callouts"
        :class="[{ 'col-12': drawer }, { 'col-xs-6 col-sm-3': responsive }]"
        class="column items-center"
        :key="key"
      >
        <q-icon
          class="q-mb-md stroke"
          :name="getIconPath(callout.icon)"
          size="xl"
        />
        <div
          :class="textClass"
          class="relative-position text-center text-letterspace text-lowercase text-shadow"
        >
          {{ callout.body }}
        </div>
      </div>
    </div>
  </div>
  <q-separator v-if="separator" />
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";

defineOptions({ name: "ProductCallouts" });

const props = defineProps({
  dark: { type: Boolean, default: false },
  drawer: { type: Boolean, default: false },
  responsive: { type: Boolean, default: true },
  separator: { type: Boolean, default: true },
  textClass: { type: String, default: "text-small" },
});

const { t } = i18n.global;
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);

const callouts = computed(() => {
  return {
    craft: {
      body: t("product.callouts.craft.body"),
      icon: t("product.callouts.craft.icon"),
    },
    design: {
      body: t("product.callouts.design.body"),
      icon: t("product.callouts.design.icon"),
    },
    materials: {
      body: t("product.callouts.materials.body"),
      icon: t("product.callouts.materials.icon"),
    },
    production: {
      body: t("product.callouts.production.body"),
      icon: t("product.callouts.production.icon"),
    },
  };
});

// const computedDark = computed(() => props.dark);
const computedDark = computed(() => false);

const getIconPath = (icon) => {
  return t(`icons.${icon}`);
};
</script>
